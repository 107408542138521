import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, popular1, popular2, popular3, skipD, skipE, skipL, firstBannerMed, secondBannerMed, thirdBannerMed, forthBannerMed, recommanded1, recommanded2, recommanded3, skipLab } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  btnVisible?: boolean;
  showMenu?: boolean;
  data?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedDiv: null | any;
  anchorElHome: boolean | null;
  anchorElTools: boolean | null;
  anchorElServices: boolean | null;
  anchorElhelp: boolean | null;
  cardDetails: any;
  medIDJourney: any;
  selectedMenu: number;
  menuItems: any;
  anchorEls: any;
  glowParams: any;
  addNewTask: boolean;
  createdTask: any;
  currentDate: any;
  showMessage: string;
  userName: string;
  medEdJourneyKnowledgeLists: any;
  isQuestionModal: boolean;
  startNewQuestions: boolean;
  questions: any;
  courseStart: boolean;
  continueLearning: any;
  formattedDuration: string;
  mouseHover: boolean;
  currentGlow: string;
  selectedDate: number;
  currentPage: any;
  questionsPerPage: any;
  medEdJourneyLists: any;
  selectMenuExplore: string;
  currentPageExplore: number;
  itemsPerPage: number;
  activeSlideIndex: number;
  sliderData: any;
  medEdExploreLists: any;
  isSortOpen: boolean;
  sortedExplore: any;
  isSearching: boolean;
  exploreSearch: string;
  exploreSearchedLists: any;
  currentTaskPage: number; // Current task page
  tasksPerPage: number;
  medEdCardDetails: any;
  isFilterOpen: boolean;
  selectedCategoryKMed: string;
  selectedLevelKMed: string
  activeDMed: any;
  currentPageDMed: any;
  dMedPerPage: any
  chartHourWeek: any;
  chartHourDay: any;
  chartHourMonth: any;
  chartHourYear: any;
  selectedTimeRange: 'day' | 'week' | 'month' | 'year',
  selectedOption: string;
  currentGraphPage: number;
  mostPopularCategory: any;
  medPopular: any;
  medRecommanded: any
  topBestContent: any;
  bestContentType: any;
  queBankAnalysis: any;
  showFirstSection: any;
  journeyView: any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Catalogue1Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollContainer: HTMLDivElement | null = null;
  newTaskApiCallID: any;
  categoryApiCallID: any;
  continueLearningApiCallID: any;
  recommendedCardApiCallID: any;
  medEdJourneyKnowledgeApiCallID: any;
  questionBankListApiCallID: any;
  medEdJourneyApiCallID: any;
  medEdExploreApiCallID: any;
  exploreSortApiCallID: any;
  exploreSearchApiCallID: any;
  medEdKnowledgeCardApiCallID: any;
  popupRef: any;
  categoryKMedApiCallID: any
  chartHourDayApiCallID: any;
  chartHourWeekApiCallID: any;
  chartHourMonthApiCallID: any;
  chartHourYearApiCallID: any;
  mostPopularApiCallID: any;
  topBestContentApiCallID: any;
  exploreFilterApiCallID: any;
  levelKMedApiCallID: any

  popupExSortRef: any;
  queBankAnalysisApiCallID: any;
  journeyViewApiCallID: any;
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedDiv: null,
      anchorElHome: null,
      anchorElTools: null,
      anchorElServices: null,
      anchorElhelp: null,
      selectedMenu: 1,
      cardDetails: [],
      medEdJourneyKnowledgeLists: [],
      medIDJourney: [],
      menuItems: [
        { id: "home", label: "Home", items: ["My Schedule", "My Files"] },
        { id: "tools", label: "My Tools", items: ["My Tools1", "My Tools"] },
        {
          id: "services",
          label: "My Services",
          items: [
            "Knowledge & Training Center",
            "Healthcare VR/AR & Gaming",
            "Marketplace",
            "Healthcare Research",
            "Healthcare Innovation & Enterpreneurship",
          ],
        },
        { id: "help", label: "Help Center", items: ["Help1"] },
      ],
      anchorEls: {},
      glowParams: {
        color: "#72efdd",
        rotate: -1,
      },
      addNewTask: false,
      createdTask: [],
      currentDate: new Date(),
      showMessage: "",
      userName: "",
      isQuestionModal: false,
      startNewQuestions: false,
      questions: [],

      courseStart: true,
      continueLearning: [],
      formattedDuration: "",
      mouseHover: false,
      currentGlow: "",
      selectedDate: -1,

      medEdJourneyLists: [],
      selectMenuExplore: "All",
      currentPageExplore: 1,
      itemsPerPage: 6,
      activeSlideIndex: 0,
      sliderData: [
        {
          id: 1,
          image: firstBannerMed,
          heading: "Dive into our world of MedEd Journeys!",
          desc: "Let's begin your educational adventure together.",
        },
        {
          id: 2,
          image: secondBannerMed,
          heading: "Expert-Led Journeys!",
          desc: "Our Journeys are designed by leading professionals in their fields.",
        },
        {
          id: 3,
          image: thirdBannerMed,
          heading: "Stay Updated!",
          desc: "Explore the latest in medicine with our Emerging Technologies Journeys.",
        },
        {
          id: 4,
          image: forthBannerMed,
          heading: "Consistency is key!",
          desc: "Dedicate at least 5–10 minutes daily to your latest journeys. Small steps, big results!",
        },
      ],
      medEdExploreLists: [],
      isSortOpen: false,
      sortedExplore: [],
      isSearching: false,
      exploreSearch: "",
      exploreSearchedLists: [],
      currentPage: 0,
      questionsPerPage: 3,
      currentTaskPage: 1, // Starting page
      tasksPerPage: 3,
      medEdCardDetails: [],
      isFilterOpen: false,
      selectedCategoryKMed: 'all categories',
      selectedLevelKMed: 'all levels',
      activeDMed: '',
      currentPageDMed: 0,
      dMedPerPage: 8,
      selectedTimeRange: 'week',
      chartHourDay: {},
      chartHourWeek: {},
      chartHourMonth: {}, // example placeholder
      chartHourYear: {},
      selectedOption: 'daily',
      currentGraphPage: 0,
      mostPopularCategory : [],
      medPopular: [
        {
          id: "1",
          type: "category",
          attributes: {
            id: 40,
            name: "Clinical Medicine",
            is_active: true,
            level: 4,
            description:
              "Gain a comprehensive understanding of anxiety disorders, diagnostic criteria, etiology, and evidence-based treatment.",
            image: {
              url: popular1,
            },
            completed_courses: 0,
            completed_percentage: 0,
            all_courses_count: 10,
            parent_category_name: "Ethics & Law",
            parent_category_icon: {
              url: skipD,
            },
            first_course_name: "Understanding Anxiety Disorders",
          },
        },
        {
          id: "2",
          type: "category",
          attributes: {
            id: 21,
            name: "Public Health",
            is_active: true,
            level: 3,
            description:
              'Study epidemiological principles and strategies for disease control, including  vaccination, and health policy.',
            image: {
              url: popular2,
            },
            completed_courses: 0,
            completed_percentage: 0,
            all_courses_count: 12,
            parent_category_name: "Foundational Sciences",
            parent_category_icon: {
              url: skipE,
            },
            first_course_name: "Epidemiology in Action",
          },
        },
        {
          id: "3",
          type: "category",
          attributes: {
            id: 36,
            name: "Diagnostic Specialties",
            is_active: true,
            level: 4,
            description:
              "Explore advanced imaging techniques used in clinical practice, including MRI, CT, ultrasound, and their applications.",
            image: {
              url: popular3,
            },
            completed_courses: 0,
            completed_percentage: 0,
            all_courses_count: 6,
            parent_category_name: "Clinical Medicine",
            parent_category_icon: {
              url: skipL,
            },
            first_course_name: "Advanced Imaging in Clinical Practice",
          },
        },
      ],
      medRecommanded: [
        {
          id: "1",
          type: "category",
          attributes: {
            id: 40,
            name: "Clinical Medicine",
            is_active: true,
            level: 1,
            description:
              "Develop essential clinical skills in patient assessment, diagnosis, and treatment planning.",
            image: {
              url: recommanded1,
            },
            completed_courses: 0,
            completed_percentage: 0,
            all_courses_count: 8,
            parent_category_name: "Ethics & Law",
            parent_category_icon: {
              url: skipD,
            },
            first_course_name: "Skills for Effective Patient Care",
          },
        },
        {
          id: "2",
          type: "category",
          attributes: {
            id: 21,
            name: "Foundational Sciences",
            is_active: true,
            level: 1,
            description:
              'Examines the structure and function of human organ systems, revealing their roles in health and disease management.',
            image: {
              url: recommanded2,
            },
            completed_courses: 0,
            completed_percentage: 0,
            all_courses_count: 10,
            parent_category_name: "Foundational Sciences",
            parent_category_icon: {
              url: skipL,
            },
            first_course_name: "Organ Systems",
          },
        },
        {
          id: "3",
          type: "category",
          attributes: {
            id: 36,
            name: "Emerging Technologies",
            is_active: true,
            level: 5,
            description:
              "Discover groundbreaking healthcare technologies, including AI, telemedicine, and wearable devices.",
            image: {
              url: recommanded3,
            },
            completed_courses: 0,
            completed_percentage: 0,
            all_courses_count: 5,
            parent_category_name: "Clinical Medicine",
            parent_category_icon: {
              url: skipLab,
            },
            first_course_name: "Health Tech Revolution",
          },
        },
      ],
      topBestContent: [],
      bestContentType: '',
      queBankAnalysis: [],
      showFirstSection: true,
      journeyView: [],
     
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.popupRef = createRef();
    // Customizable Area Start 
    this.popupExSortRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.newTaskApiCallID) {
        this.setState({
          createdTask: responseJson.today_tasks.data,
          showMessage: responseJson.message,
        });
      } else if (apiRequestCallId === this.medEdKnowledgeCardApiCallID) {
        this.setState({ medEdCardDetails: responseJson.data });
      } else if (apiRequestCallId === this.continueLearningApiCallID) {
        this.setState({ continueLearning: responseJson.data });
      } else if (apiRequestCallId === this.medEdJourneyApiCallID) {
        this.setState({ medEdJourneyLists: responseJson.data });
      } else if (
        apiRequestCallId === this.medEdExploreApiCallID ||
        apiRequestCallId === this.exploreSortApiCallID || 
        apiRequestCallId === this.exploreFilterApiCallID
      ) {
        this.setState({ medEdExploreLists: responseJson.data });
      } else if (apiRequestCallId === this.exploreSearchApiCallID) {
        this.setState({ exploreSearchedLists: responseJson.data });
      } if (apiRequestCallId === this.categoryKMedApiCallID || apiRequestCallId === this.levelKMedApiCallID) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
      
      const apiCallHandlers = {
        [this.categoryApiCallID]: () => 
          this.setState({ medIDJourney: responseJson.data }),
        [this.medEdJourneyKnowledgeApiCallID]: () => 
          this.setState({ medEdJourneyKnowledgeLists: responseJson.data }),
        [this.recommendedCardApiCallID]: () => 
          this.setState({ cardDetails: responseJson.recommended_courses }),
        [this.questionBankListApiCallID]: () =>
          this.setState({ questions: responseJson.data }),
        [this.chartHourDayApiCallID]: () =>
          this.setState({ chartHourDay: responseJson }),
        [this.chartHourWeekApiCallID]: () =>
          this.setState({ chartHourWeek: responseJson }),
        [this.chartHourMonthApiCallID]: () =>
          this.setState({ chartHourMonth: responseJson }),
        [this.chartHourYearApiCallID]: () =>
          this.setState({ chartHourYear: responseJson }),
        [this.mostPopularApiCallID]: () =>
          this.setState({ mostPopularCategory: responseJson }),
        [this.queBankAnalysisApiCallID]: () =>
          this.setState({ queBankAnalysis: responseJson }),
        [this.journeyViewApiCallID]: () =>
          this.setState({ journeyView: responseJson.data }), 
        [this.topBestContentApiCallID]: () => {
          const { bestContentType } = this.state;
          const topBestContent = 
              (bestContentType === 'day' || bestContentType === 'week')
                  ? responseJson.top_sections
                  : responseJson.top_courses;
          this.setState({ topBestContent });
      }
      };
      const handler = apiCallHandlers[apiRequestCallId];
      if (handler) {
        handler();
      }
    }
    // Customizable Area End
  }

  handleApiResponse(apiRequestCallId: any, responseJson: any) {
    const updateKnowledgeLists = (key: any, value: any) => {
        const data = (this.state as any)[key] !== value
            ? this.transformData(responseJson).data
            : responseJson.data;
        this.setState({ medEdJourneyKnowledgeLists: data });
    };

    if (apiRequestCallId === this.categoryKMedApiCallID) {
        updateKnowledgeLists('selectedCategoryKMed', 'all categories');
    } else if (apiRequestCallId === this.levelKMedApiCallID) {
        updateKnowledgeLists('selectedLevelKMed', 'all levels');
    }
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  setActiveSlideIndex = (e: any) => {
    this.setState({ activeSlideIndex: e });
  };

  scrollLeft = () => {
    if (this.scrollContainer) {
        this.scrollContainer.scrollBy({
            left: -100, // Scroll left by 100px
            behavior: 'smooth'
        });
    }
};

scrollRight = () => {
    if (this.scrollContainer) {
        this.scrollContainer.scrollBy({
            left: 100, // Scroll right by 100px
            behavior: 'smooth'
        });
    }
};

  handleDotClickGraph = (pageIndex: number) => {
    this.setState({ currentGraphPage: pageIndex });
  };

  handleMenuSelected = (data: any) => {
    this.setState({ selectMenuExplore: data.name });
    this.exploreFilter(data.id)
  };

  handleExploreSort = () => {
    this.setState((prevState) => ({ isSortOpen: !prevState.isSortOpen }), () => {
      if (this.state.isSortOpen) {
        document.addEventListener('mousedown', this.handleClickOutsideExSort);
      } else {
        document.removeEventListener('mousedown', this.handleClickOutsideExSort);
      }
    });
  };

  handleClickOutsideExSort = (event: any) => {
    if (this.popupExSortRef.current && !this.popupExSortRef.current.contains(event.target)) {
      this.setState({ isSortOpen: false }, () => {
        document.removeEventListener('mousedown', this.handleClickOutsideExSort);
      });
    }
  };

  handleExploreSearch = (event: any) => {
    const searchTerm = event.target.value;
    this.setState(
      { exploreSearch: searchTerm },
      () => {
        if (searchTerm) {
          this.exploreSearch(searchTerm); 
        } else {
          this.setState({ exploreSearchedLists: [] });
        }
      }
    );
  };

  handlePageChangeExplore = (pageNumber: any) => {
    this.setState({ currentPageExplore: pageNumber });
  };

  exploreFilter = async (id: any) => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exploreFilterApiCallID = requestMessage.messageId;
    id == 0 ? requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/search_categories`
    )
    : requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/search_categories?parent_category_id=${id}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSort = async (type: string) => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exploreSortApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/search_categories?order_by=${type}` 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  exploreSearch = async (search: string) => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exploreSearchApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/search_categories?title=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleStopPropogate = (e: any) => {
    e.stopPropagation();
  }

  handleStopPropogateFilter = (e: any) => {
    e.stopPropagation();
    this.setState(
      (prevState) => ({ isFilterOpen: !prevState.isFilterOpen })
    );
  }

  handleClickOutside = (event: any) => {
    if (this.popupRef.current && !this.popupRef.current.contains(event.target)) {
      this.setState({ isFilterOpen: false });
    }
  };

  transformData = (inputData: any) => {
    return {
        data: inputData.data && inputData.data.map((item: any) => {
            // Extract relevant data
            const attributes = item.data.attributes;
            return {
                id: item.data.id,
                type: item.data.type,
                attributes: {
                    id: attributes.id,
                    name: attributes.name,
                    is_active: attributes.is_active,
                    level: attributes.level,
                    description: attributes.description,
                    image: {
                        url: attributes.image.url
                    },
                    completed_courses: attributes.completed_courses,
                    completed_percentage: attributes.completed_percentage,
                    all_courses_count: attributes.all_courses_count,
                    parent_category_name: attributes.parent_category_name,
                    parent_category_icon: {
                        url: attributes.parent_category_icon.url
                    },
                    first_course_name: attributes.first_course_name
                }
            };
        })
    };
}

  handleCategoryChange = async (event: any) => {
    this.setState({ selectedCategoryKMed: event.target.value });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryKMedApiCallID = requestMessage.messageId;
    
    if(event.target.value == 'all categories') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories/get_list_journeys`
      )
    }else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories/filter_by_parent_categories?parent_category_id=${event.target.value}`
      );
    } 
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleLevelChange = async (e: any) => {
    this.setState({ selectedLevelKMed: e.target.value });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.levelKMedApiCallID = requestMessage.messageId;
    
    if(e.target.value == 'all levels') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories/get_list_journeys`
      )
    }else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories/filter_by_level?level=${e.target.value}`
      );
    } 
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  componentDidMount = async () => {
    const id = window.location.pathname.split("/")[2]
   
    await this.onClickCategory(this.state.activeDMed);
    await this.onRecommendedCard();
    let usrName = await getStorageData("userName");
    this.setState({ userName: usrName });
    await this.onMedEdKnowledgeLists();
    await this.listofQuestionBank();
    await this.onContinueLearning();
    await this.onNewTask();
    await this.listOfMedEdJourney();
    await this.listOfMedEdExplore();
    await this.onChartHourWeek();
    await this.onmostPopular("week");
    await this.onTopBestContent("week");
    await this.onQueBankAnalysis("week");
    await this.onJourneyView(id);

    if ((this.props.id as any) === "3") {
      this.setState({ selectedMenu: 3 });
    }

    if ((this.props.id as any) === "2") {
      this.setState({ selectedMenu: 2 });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('mousedown', this.handleClickOutsideExSort);
  };

  async componentWillUnmount(): Promise<void> {
    document.removeEventListener('mousedown', this.handleClickOutsideExSort);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onChartHourDay = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourDayApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourDayEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onChartHourWeek = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourWeekApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourWeekEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onChartHourMonth = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourMonthApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourMonthEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onChartHourYear = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourYearApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourYearEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onmostPopular = async (type: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.mostPopularApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mostPopularEndPoint}${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onTopBestContent = async (type: string) => {
    this.setState({bestContentType: type})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.topBestContentApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.topBestContentEndPoint}${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
onJourneyView = async (id: any) => {
  console.log("onJourneyView called with ID:", id);
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: await getStorageData("signupToken"),
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.journeyViewApiCallID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.journeyViewEndPoint}${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  onQueBankAnalysis = async (type: string) => {
    this.setState({bestContentType: type})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.queBankAnalysisApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.queBankAnalysisEndPoint}${type}&time_zone=Asia/Kolkata`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  goToTermsAndConditions = () => {
    this.props.navigation.navigate("TermsAndConditions");
  };

  handlePageChange = (pageIndex: any) => {
    this.setState({ currentPage: pageIndex });
  };
  
  chunkItems = () => {
    const chunkedItems = [];
    for (let i = 0; i < this.state.medEdJourneyLists.length; i += this.state.dMedPerPage) {
      chunkedItems.push(this.state.medEdJourneyLists.slice(i, i + this.state.dMedPerPage));
    }
    return chunkedItems;
  };

  handlePageChangeDMed = (pageIndex: any) => {
    this.setState({ currentPageDMed: pageIndex });
  };

  getItemsForCurrentPage = () => {
    const chunkedItems = this.chunkItems();
    return chunkedItems[this.state.currentPage] || [];
  };

  // handlePageChangeTask = (newPage: number) => {
  //   this.setState({ currentTaskPage: newPage });
  // };

  getClassName(priority: string) {
    if (priority === 'low') {
      return 'greenCircle';
    } else if (priority === 'medium') {
      return 'yellowCircle';
    } else if (priority === 'high') {
      return 'redCircle';
    } else {
      return '';
    }
  }
  handleScheduleClick = () => {
    this.props.navigation.navigate("Scheduling");
  };

  onMenuItemClick = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "KnowledgeTrainingCenter"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onGoBack = () => {
    const {navigation} = this.props;
    if(navigation) {
      navigation.goBack()
    }
  };

  onMedEdJourney = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "MedEdJourney"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleJourney = (id: any) => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.navigate("Journey", { id });
    }
    
    this.onJourneyView(id); 
  };

  onClickHome = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Catalogue1"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onRecommendedCard = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recommendedCardApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recommendedCardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onNewTask = async () => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newTaskApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/today_task?time_zone=${"Asia/Kolkata"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickCategory = async (name: any) => {
    this.setState({activeDMed: name})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdCardKnowledgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onMedEdKnowledgeLists = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdJourneyKnowledgeApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdJourneyKnowledgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listOfMedEdJourney = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdJourneyApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdJourneyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listOfMedEdExplore = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdExploreApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdExploreEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listofQuestionBank = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.questionBankListApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionBankListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onContinueLearning = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.continueLearningApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.continueLearningEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
