// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Questionbank1 from "../../blocks/questionbank1/src/Questionbank1";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Analytics1 from "../../blocks/analytics1/src/Analytics1";
import Catalogue1 from "../../blocks/catalogue1/src/Catalogue1";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Keywordsearch from "../../blocks/keywordsearch/src/Keywordsearch";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Dataencryption from "../../blocks/dataencryption/src/Dataencryption";
import SignUp from "../../blocks/email-account-registration/src/SignUp.web";
import TermsAndConditions from "../../blocks/email-account-registration/src/TermsAndConditions.web";
import CreatePassword from "../../blocks/email-account-registration/src/CreatePassword.web";
import CreateCountry from "../../blocks/email-account-registration/src/CreateCountry.web";
import ValidateEmail from "../../blocks/email-account-registration/src/ValidateEmail.web";
import EmailValidated from "../../blocks/email-account-registration/src/EmailValidated.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPasswordWeb";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPasswordWeb";
import PasswordReset from "../../blocks/forgot-password/src/PasswordReset";
import NewPasswordSet from "../../blocks/forgot-password/src/NewPasswordSet";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import LearningVertical from "../../blocks/customform/src/LearningVertical.web";
import MyRole from "../../blocks/customform/src/MyRole.web";
import MedicalJourney from "../../blocks/customform/src/MedicalJourney.web";
import MyInstitution from "../../blocks/customform/src/MyInstitution.web";
import MyImmediateGoals from "../../blocks/customform/src/MyImmediateGoals.web";
import MyFutureGoals from "../../blocks/customform/src/MyFutureGoals.web";
import PersonalizationComplete from "../../blocks/customform/src/PersonalizationComplete.web";
import PersonalizationLoader from "../../blocks/customform/src/PersonalizationLoader.web";
import KnowledgeTrainingCenter from "../../blocks/catalogue1/src/KnowledgeTrainingCenter.web";
import MedEdJourney from "../../blocks/catalogue1/src/MedEdJourney.web";
import Journey from "../../blocks/catalogue1/src/Journey.web";
import CoursePage from "../../blocks/catalogue/src/CoursePage.web"

const routeMap = {
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Videos:{
 component:Videos,
path:"/Videos"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Questionbank1:{
 component:Questionbank1,
path:"/Questionbank1"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Signuplogin:{
 component:Signuplogin,
path:"/Signuplogin"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Analytics1:{
 component:Analytics1,
path:"/Analytics1"},
Catalogue1:{
 component:Catalogue1,
path:"/Catalogue1"},
Dataencryption2:{
 component:Dataencryption2,
path:"/Dataencryption2"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Automaticreminders:{
 component:Automaticreminders,
path:"/Automaticreminders"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Keywordsearch:{
 component:Keywordsearch,
path:"/Keywordsearch"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Dataencryption:{
 component:Dataencryption,
path:"/Dataencryption"},
SignUp:{
  component: SignUp,
path:"/SignUp"},
CreatePassword:{
  component: CreatePassword,
path:"/CreatePassword"},
CreateCountry:{
  component: CreateCountry,
path:"/CreateCountry"},
ValidateEmail:{
  component: ValidateEmail,
path:"/ValidateEmail"},
EmailValidated:{
  component: EmailValidated,
path:"/EmailValidated"},
ForgotPasswordWeb:{
  component: ForgotPasswordWeb,
path:"/ForgotPasswordWeb"},
PasswordReset:{
  component: PasswordReset,
path:"/PasswordReset"},
NewPasswordWeb:{
  component: NewPasswordWeb,
path:"/NewPasswordWeb"},
NewPasswordSet:{
  component: NewPasswordSet,
path:"/NewPasswordSet"},
CustomForm:{
  component: CustomForm,
path:"/CustomForm"},

MyRole:{
  component: MyRole,
  path:"/MyRole",
},
LearningVertical:{
  component: LearningVertical,
path:"/LearningVertical"},

MedicalJourney:{
  component: MedicalJourney,
path:"/MedicalJourney"},
MyInstitution:{
  component: MyInstitution,
path:"/MyInstitution"},
MyImmediateGoals:{
  component: MyImmediateGoals,
path:"/MyImmediateGoals"},
MyFutureGoals:{
  component: MyFutureGoals,
path:"/MyFutureGoals"},
PersonalizationComplete:{
  component: PersonalizationComplete,
path:"/PersonalizationComplete"},
PersonalizationLoader:{
  component: PersonalizationLoader,
path:"/PersonalizationLoader"},
TermsAndConditions:{
  component: TermsAndConditions,
path:"/TermsAndConditions"},
KnowledgeTrainingCenter:{
  component:KnowledgeTrainingCenter,
 path:"/KnowledgeTrainingCenter"},
 MedEdJourney:{
  component:MedEdJourney,
 path:"/MedEdJourney"},
 Journey:{
  component:Journey,
 path:`/Journey/:id`},

LandingPage: {
  component: LandingPage,
  path: '/',
  exact: true
},
InfoPage: {
  component: InfoPage,
  path: '/InfoPage'
},

AlertWeb: {
  component: AlertBlock,
  path: "*/AlertWeb",
  modal: true
},
Course: {
  component: CoursePage,
  path: "/Course/:courseID",
  exact: true
}

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </div>
    );
  }
}

export default App;