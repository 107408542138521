Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.coureseDetailsEndpoint = "bx_block_categories/course_details?sub_category_id";
exports.subCategoryEndpoint = "bx_block_categories/sub_category_by_id?sub_category_id";
exports.unitPercentagesEndpoint = "bx_block_profile/percentage_of_unit?unit_id";
exports.questionBankScoreEndpoint = "bx_block_profile/questionbank_percentage_of_unit?unit_id";
// Customizable Area End