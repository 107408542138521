import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
interface Station {
  label: string;
  score: number;
  color: string;
  x: number;
  y: number;
  completed: boolean;
  description?: string;
  order?: number;
}
interface UnitPercentage {
  unit_name: string;
  completion_percentage: string;
}
interface QuestionBankScore {
  unit_name: string;
  overall_questionbank_score: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  showFinalPath: boolean;
  stations: Station[];
  pathRef: React.RefObject<SVGPathElement>;
  courseDetails: {
    expected_duration: string;
    learners_enrolled: number;
    last_update: string;
  };
  subCategoryData: string | null;
  thumbnail: string;
  unitPercentages: UnitPercentage[];
  questionBankScore: QuestionBankScore[];
  selectedStation: number | null;
  stationRefs: React.RefObject<SVGCircleElement>[];
  popupPosition: {
    top: number;
    left: number;
    maxWidth: string;
    position: "fixed";
    transform: string;
    zIndex: number;
  } | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CoursePageController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getSubCategoryCallId: any;
  getCourseDetailsCallId: any;
  getUnitPercentageCallIds: string[] = [];
  getQueBankScoreCallIds: string[] = [];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      showFinalPath: false,
      stations: [
        {
          label: "Loading...",
          score: 0,
          color: "#4CD6C9",
          x: 290,
          y: 10,
          completed: false,
          order: 1,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#FFB74D",
          x: 403,
          y: 177,
          completed: false,
          order: 2,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#4CD6C9",
          x: 136,
          y: 177,
          completed: false,
          order: 3,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#FF7875",
          x: 290,
          y: 343,
          completed: false,
          order: 4,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#4CD6C9",
          x: 403,
          y: 510,
          completed: false,
          order: 5,
          description: "Loading...",
        },
      ],
      pathRef: React.createRef(),
      courseDetails: {
        expected_duration: "",
        learners_enrolled: 0,
        last_update: "",
      },
      subCategoryData: null,
      thumbnail: "",
      unitPercentages: [],
      questionBankScore: [],
      selectedStation: null,
      stationRefs: Array(5)
        .fill(null)
        .map(() => React.createRef()),
      popupPosition: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const courseID = this.props.navigation.getParam('courseID', null);
    const token = await getStorageData("signupToken");
    this.setState({ token: token }, () => {
      this.getCourseDetails(courseID, token);
      this.getSubCategoryData(courseID, token);
    });
    this.updatePathProgress();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getCourseDetailsCallId) {
      if (responseJson?.expected_duration) {
        this.setState({
          courseDetails: responseJson,
        });
      }
    }

    if (apiRequestCallId === this.getSubCategoryCallId) {
      if (responseJson?.data) {
        const units = responseJson.data.attributes.units.data;
        const updatedStations = [...this.state.stations];
        this.getUnitPercentageCallIds = [];
        this.getQueBankScoreCallIds = [];
        units.forEach((unit: any, index: number) => {
          if (index < 5) {
            updatedStations[index] = {
              ...updatedStations[index],
              label: unit.attributes.name,
              description: unit.attributes.description,
            };
            this.getUnitPercentage(unit.attributes.id);
            this.getQuestionBankScore(unit.attributes.id);
          }
        });

        this.setState({
          subCategoryData: responseJson.data,
          stations: updatedStations,
          thumbnail: responseJson.data.attributes.image.url,
          unitPercentages: [],
          questionBankScore: [],
        });
      }
    }
    if (this.getUnitPercentageCallIds.includes(apiRequestCallId)) {
      if (responseJson?.completion_percentage) {
        this.setState((prevState) => {
          const newUnitPercentages = [
            ...prevState.unitPercentages,
            responseJson,
          ];

          const updatedStations = prevState.stations.map((station) => {
            if (responseJson.unit_name === station.label) {
              return {
                ...station,
                completed:
                  parseFloat(responseJson.completion_percentage) >= 100,
              };
            }
            return station;
          });

          return {
            unitPercentages: newUnitPercentages,
            stations: updatedStations,
          };
        });
      }
    }

    // For question bank scores
    if (this.getQueBankScoreCallIds.includes(apiRequestCallId)) {
      if (responseJson?.overall_questionbank_score) {
        this.setState((prevState) => {
          return {
            questionBankScore: [...prevState.questionBankScore, responseJson],
          };
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate() {
    this.updatePathProgress();
  }

  updatePathProgress = () => {
    const path = this.state.pathRef.current;
    if (path) {
      const length = path.getTotalLength();
      path.style.strokeDasharray = length.toString();

      const lastCompletedIndex = this.state.stations
        .filter((station) => station.completed)
        .reduce((acc, station, index) => (station.completed ? index : acc), -1);

      const nextStation = this.state.stations[lastCompletedIndex + 1];
      if (!nextStation) {
        path.style.strokeDashoffset = "0";
        return;
      }

      let accumulatedLength = 0;
      for (let i = 0; i <= length; i++) {
        const point = path.getPointAtLength(i);
        if (
          Math.abs(point.x - nextStation.x) < 1 &&
          Math.abs(point.y - nextStation.y) < 1
        ) {
          accumulatedLength = i;
          break;
        }
      }

      path.style.strokeDashoffset = (length - accumulatedLength).toString();
    }
  };

  createMainPath = () => {
    const radius = 80;
    let path = `M ${this.state.stations[0].x} ${this.state.stations[0].y}`;
    path += ` L 450 ${this.state.stations[0].y}`;
    path += ` A ${radius} ${radius} 0 0 1 450 ${this.state.stations[1].y}`;
    path += ` L 100 ${this.state.stations[1].y}`;
    path += ` A ${radius} ${radius} 0 0 0 100 ${this.state.stations[3].y}`;
    path += ` L 290 ${this.state.stations[3].y}`;
    path += ` L 450 ${this.state.stations[3].y}`;
    path += ` A ${radius} ${radius} 0 0 1 450 ${this.state.stations[4].y}`;
    path += ` L 390 ${this.state.stations[4].y}`;
    return path;
  };

  getCourseDetails = (courseID: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCourseDetailsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.coureseDetailsEndpoint}=${courseID}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  getSubCategoryData = (courseID: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSubCategoryCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategoryEndpoint}=${courseID}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  getUnitPercentage = (unitId: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = apiRequest.messageId;

    // Track this specific call ID
    this.getUnitPercentageCallIds.push(callId);

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitPercentagesEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };
  getQuestionBankScore = (unitId: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = apiRequest.messageId;

    // Track this specific call ID
    this.getQueBankScoreCallIds.push(callId);

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionBankScoreEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  calculatePopupPosition = (index: number) => {
    const circleRef = this.state.stationRefs[index];
    if (!circleRef.current) return null;

    const circle = circleRef.current;
    const circleRect = circle.getBoundingClientRect();
    const popupWidth = 350;
    const popupOffset = 20;

    return {
      position: "fixed" as const,
      top: circleRect.top + circleRect.height + popupOffset,
      left: circleRect.left + circleRect.width / 2 - popupWidth / 2,
      maxWidth: `${popupWidth}px`,
      transform: "translate3d(0, 0, 0)",
      zIndex: 1001,
    };
  };

  handleStationClick = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    const newPosition = this.calculatePopupPosition(index);

    this.setState({
      selectedStation: this.state.selectedStation === index ? null : index,
      popupPosition: newPosition,
    });
  };
  // Customizable Area End
}
