import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { library, play, popular1, schedule, } from "./assets";
import Footer from "./Footer.web";
import "./Header.css";
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import Header from "./Header.web";
import SubHeader from "./SubHeader.web";

export default class Journey extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
   
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr}>
        <Header navigation={this.props.navigation} id={"3"} />
        <SubHeader data={this.state.journeyView} navigation={this.props.navigation} id={this.props.id} />
        <Box className="cardParent" style={{display: 'flex', alignItems: 'center'}}>
        {[...Array(3)].map((_, index) => (
          <Box key={index} className={`cardMapContainer boxJourney ${index == 0 && 'outline'}`}>
            <div className="comingsoon"><p>Coming Soon</p></div>
              <Box style={{ position: "relative" }}>
                {index == 0 && <Box style={{position: 'absolute', top: '20px', left: '20px', color: 'rgba(255,255,255,0.6)', zIndex: 22, 
                  textTransform: 'uppercase', background: '#7EBFB8', padding: '4px 10px', borderRadius: '20px',
                  fontWeight: 'bold', fontSize: '13px'
                  }}>
                  Recommended
                </Box>}
              <div className="cardimagedata"
                  style={{
                    width:  "100%",
                    height: "200px",
                    opacity: index == 0 ? 1: '0.5',
                    margin: "-4px 0px 0px",
                    backgroundImage : `url(${popular1})`,
                    position: "relative",
                    backgroundSize: 'auto 230px',
                    backgroundPosition: '-20px -5px',
                    backgroundRepeat: 'no-repeat',
                    objectFit: 'cover'
                    
                  }}
                  />
                
                <Box
                  style={{
                    bottom: "24px",
                    left: "25px",
                    position: "absolute",
                    justifyContent: "space-between",
                    display: "flex",
                    width: "88%",
                  }}
                >

                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                      <Typography className="cardTitle">
                        Cardio
                      </Typography>
                      <Box style={{background: 'rgba(255,255,255,0.3)', borderRadius: '20px', padding: '2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={play} width={15} height={15}/>
                      <Button style={{padding: '0px'}}>
                        <p style={{fontSize: '12px', color: 'rgba(255,255,255,0.8)', textTransform: 'capitalize'}}>Preview</p>
                      </Button>
                      </Box>
                    </Box>
                  </Box>
              </Box>
              <Box
                style={{
                  padding: "30px 20px",
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
                  backgroundColor: "#172130",
                  margin: "-5px 5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ display: "flex" }}>
                  <img src={library}
                      style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                    />
                    <Typography
                      style={{
                        fontFamily: "Raleway",
                        color: "rgba(255,255,255,0.6)",
                        fontSize: "13px",
                      }}
                      className="font-family-p"
                    >
                      5 units
                    </Typography>
                  </Box>
                  <Box
                    fontSize="small"
                    style={{  marginRight: 5, display: "flex", gap:4 }}
                  >
                    <img src={schedule}
                      style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                    />
                    <Typography
                      style={{ color: "rgba(255,255,255,0.6)", fontSize: "13px"}}
                      className="font-family-p"
                    >
                      6h 30 min
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <div className="course_desc" style={{ overflow: "auto", height: "60px" }}>
                    <Typography
                      style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px" }}
                    >
                      Explore the complexity of heart and blood vessel function 
                    </Typography>
                  </div>
                  
                </Box>
                <Box style={{ textAlign: "center" }} />
              </Box>
          </Box>
        ))}
        </Box>
        <Button
            className="quebankbtns"
            variant="contained"
            style={{
              color: "white",
              width: "14%",
              maxWidth: '14%',
              background:
                "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
              borderRadius: "20px",
              height: "32px",
              padding: "2px",
              overflow: "hidden",
              letterSpacing: ".7px",
              textTransform: "capitalize",
              marginLeft: '42%',
              marginBottom: '62px'
            }}
          >
            Get Started!
          </Button>
        <Footer navigation={this.props.navigation} id={""} />
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  statsBodyy: {
    opacity: "30% !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  } as const,
  buttonStyle: {
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    width: "100%",
    height: "45px",
    marginTop: "40px",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px",
  },

  imgIconBack: {
    background: "rgba(51, 65, 85, 1)",
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  plusBtnStyle: {
    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    color: "white",
    borderRadius: "50%",
    padding: 5,
    width: "32px !important",
    height: "32px !important",
  },
  imgQ: {
    width: 14,
    height: 20,
  },
  subHeadingText: {
    fontWeight: 700,
    fontSize: "20px",
    color: "rgba(255,255,255,1)",
  },
};
// Customizable Area End
