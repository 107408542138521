Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "catalogue1";
exports.labelBodyText = "catalogue1 Body";
exports.newTaskApiEndPoint= "/tasks/tasks"
exports.categoryEndPoint= "/bx_block_categories/categories"
exports.recommendedCardEndPoint = "/bx_block_categories/prioritized_recommendation"
exports.btnExampleTitle = "CLICK ME";
exports.medEdJourneyKnowledgeEndPoint="/bx_block_categories/get_list_journeys"
exports.questionBankListEndPoint="/bx_block_assessmenttest/questionbanks"
exports.continueLearningEndPoint="/bx_block_profile/continue_learning"
exports.medEdJourneyEndPoint="/bx_block_categories/get_list_categories"
exports.medEdExploreEndPoint="/bx_block_categories/search_categories"
exports.chartHourYearEndPoint="bx_block_signuplogin/yearly_activity"
exports.chartHourMonthEndPoint="/bx_block_signuplogin/monthly_activity"
exports.chartHourWeekEndPoint="/bx_block_signuplogin/weekly_activity"
exports.chartHourDayEndPoint="/bx_block_signuplogin/daily_activity"
exports.mostPopularEndPoint="/bx_block_assessmenttest/top_categories_by_timeframe?time_frame="
exports.topBestContentEndPoint="/bx_block_assessmenttest/top_based_content?time_frame="
exports.queBankAnalysisEndPoint="/bx_block_assessmenttest/questionbank_performance?range="
exports.journeyViewEndPoint="/bx_block_categories/category_by_id?category_id="
// Customizable Area End